<template>
  <div class="container">
    <h1>הצהרת נגישות</h1>
    <p><strong>תאריך עדכון הצהרת נגישות 10.10.2024</strong></p>
    <p>
      עמותת נשי אמית שמה לעצמה למטרה לאפשר לכלל האוכלוסייה, כולל לאנשים בעלי
      מוגבלות זו או אחרת, נגישות מלאה לאתר האינטרנט ותכניו.
    </p>
    <p>
      החברה מאמינה בזכויות שוות והזדמנויות שוות להפקת אותה תועלת מתכני האתר ולכן
      פועל לקידום נגישות האתרים הישראלים והפיכתם למתאימים לכלל האוכלוסייה
      בישראל, על פי המצוין בחוק ובתקנות הנגישות. בנוסף להנגשת האתר, גם צוות
      החברה עובר בימים אלו הדרכה במתן שירות לקוחות נגיש.
    </p>
    <p><strong>הנגשת האתר</strong></p>
    <p>
      הנגשת האתר מתבצעת בהתאם לתקן הישראלי לנגישות 5568 והנחיות גוף התקינה
      הבין-לאומי W3C לרמה AA. היכן מפעילים את ההנגשה באתר?
    </p>
    <p>
      בפינה השמאלית התחתונה באתר skilly.amit.lemida.org, נמצא כפתור "הנגשה". בעת
      הקלקה על כפתור זה, יפתח תפריט המציע לגולש את מגוון סוגי ההנגשות האפשריים
      באתר.
    </p>
    <p>לאחר בחירת סוג ההנגשה הרצוי, והקלקה עליו, ייטען האתר במצב המונגש.</p>
    <p>להפסקת ההנגשה, יש ללחוץ על "בטל הנגשה" המופיע גם הוא בתפריט.</p>
    <p><strong>התאמות עיקריות</strong></p>
    <p>
      צבעים: שינוי ניגודיות צבעים על בסיס רקע כהה ו/או בהיר. התאמת האתר לעיוורי
      צבעים.
    </p>
    <p>מקלדת: מתן אפשרות ניווט על ידי המקלדת</p>
    <p>תוכן האתר:</p>
    <ul>
      <li>הנגשת התמונות באתר</li>
      <li>הנגשת תפריטים באתר</li>
      <li>הגדלת הפונט למספר גדלים שונים</li>
      <li>התאמת האתר עבור טכנולוגיות מסייעות</li>
      <li>הצהרת נגישות</li>
    </ul>
    <p><strong>משוב גולשים</strong></p>
    <p>צוות עמותת נשי אמית שואף לתת לכל לקוחותיו את השירות הטוב ביותר.</p>
    <p>
      במידה ובמהלך הגלישה נתקלתם בכל זאת במגבלה שהפריעה לגלישה או מנעה אותה
      לחלוטין, נשמח לשמוע על כך! אנא ידעו אותנו באמצעות טופס "צור קשר".
    </p>
    <p>
      האתר נבדק בקורא מסך מסוג NVDA והינו מותאם לגלישה בדפדנים הנפוצים (כרום,
      מוזילה, אדג').
    </p>
    <p><strong>הסדרי נגישות במשרדי החברה:</strong></p>
    <ul>
      <li>
        <a
          href="https://amit.org.il/%d7%a0%d7%92%d7%99%d7%a9%d7%95%d7%aa-%d7%91%d7%91%d7%aa%d7%99-%d7%94%d7%a1%d7%a4%d7%a8/"
          class="link"
          target="_blank">
          נגישות בבתי הספר לחץ כאן
        </a>
      </li>
      <li>
        <a
          href="https://amit.org.il/%d7%a0%d7%92%d7%99%d7%a9%d7%95%d7%aa-%d7%91%d7%9e%d7%9b%d7%9c%d7%9c%d7%95%d7%aa/"
          class="link"
          target="_blank">
          נגישות במכללות לחץ לכאן
        </a>
      </li>
      <li>
        <a
          href="https://amit.org.il/%d7%a0%d7%92%d7%99%d7%a9%d7%95%d7%aa-%d7%91%d7%92%d7%95%d7%92%d7%99%d7%94/"
          class="link"
          target="_blank">
          נגישות בגוגיה לחץ לכאן
        </a>
      </li>
    </ul>
    <p><strong>פרטי רכז/ת נגישות בחברה</strong></p>
    <p>למידע נוסף בנושא, ניתן לפנות לרכזת הנגישות בחברה – איילת פרידמן</p>
    <p>טלפון: 050-9265949 אימייל: ayeletf@amit.org.il</p>
    <p><strong>המנגיש:</strong></p>
    <p class="flex-nowrap">
      תהליך ההנגשה בוצע על ידי חברה להנגשת אתרים
      <a href="https://accessible.org.il/" class="link" target="_blank">
        "הנגשת אתרים לישראל"
      </a>
      בשיתוף עם חברת ואגס VGS
      <a href="https://www.vagas.co.il/" class="link" target="_blank">
        בניית אתרים
      </a>
      .
    </p>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped>
.container {
  padding: 1rem;
}
h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
p {
  margin-bottom: 0.5rem;
}
p strong {
  display: block;
  margin-top: 20px;
}
.flex-nowrap {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}
ul {
  list-style-type: disc;
  padding-left: 1rem;
  margin-bottom: 1rem;
}
.link {
  color: blue;
  text-decoration: underline;
  margin-right: 3px;
  margin-left: 3px;
}
</style>
